
export const ADDRESS = 'address'
export const UNIT_NUMBER = 'unitNumber'
export const CITY = 'city'
export const STATE_OR_TERITORY = 'stateOrTerritory'
export const POSTCODE = 'postcode'
export const GENDER = 'gender'
export const GENDER_ID = 'genderId'
export const EMPLOYMENT_STATUS = 'employmentStatus'
export const DATE_OF_BIRTH = 'date'
export const EMPLOYMENT_STATUS_CASUAL = 'employmentStatusCasual'

export const YOUR_NEXT_OF_KIN_DATA = 'yourNextOfKinData'
export const KIN_FIRST_NAME = 'kinFirstName'
export const KIN_LAST_NAME = 'kinLastName'
export const KIN_PHONE_NUMBER = 'kinPhoneNumber'
export const KIN_RELATION = 'kinRelation'
export const KIN_ADDRESS = 'kinAddress'
export const KIN_UNIT_NUMBER = 'kinUnitNumber'
export const KIN_CITY = 'kinCity'
export const KIN_STATE_OR_TERITORY = 'kinStateOrTerritory'
export const KIN_POSTCODE = 'kinPostcode'

export const GUARDIAN_DATA = 'guardianData'
export const GUARDIAN_FIRST_NAME = 'guardianFirstName'
export const GUARDIAN_LAST_NAME = 'guardianLastName'
export const GUARDIAN_EMAIL = 'guardianEmail'
export const GUARDIAN_PHONE_NUMBER = 'guardianPhoneNumber'